import React from "react";
import FillOutComplianceForms from "./fill-out-compliance-form";
import { AuthorizePatient } from "components/new-email-order/authorize-patient";

const ComplianceForm = () => {

  return (
    <AuthorizePatient>
      <FillOutComplianceForms />
    </AuthorizePatient>);
  /*
  //const authToken = useSelector(selectors.getAuthenticationToken);
  return (
    authToken ?
    <FillOutComplianceForms />
    :
    <AuthorizePatient>
      <FillOutComplianceForms />
    </AuthorizePatient>
  );
  */
};

export default ComplianceForm;
