import { NotificationManager } from "react-notifications";
import { API } from "actions/api";
import browserHistory from "browser-history";

export const fetchComplianceForms = () => async dispatch => {
  try {
    const result = await dispatch({
      type: API,
      route: "patient_portal/mobile/compliance-forms",
      method: "GET"
    });

    return result.complianceForms;
  } catch (error) {
    console.error(error.message);
    NotificationManager.error(error.message);
  }
};

export const fetchComplianceForm = (formId, auth) => async dispatch => {
  try {
    const { FormToPatient } = await dispatch({
      type: API,
      route: `patient_portal/mobile/compliance-form/${formId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`
      }
    });

    return FormToPatient;
  } catch (error) {
    console.error(error.message);
    NotificationManager.error(error.message);
  }
};

export const submitForm =
  (formId, body, token = "") =>
  async dispatch => {
    try {
      let form = {
        type: API,
        route: "patient_portal/mobile/compliance-form/save",
        method: "POST",
        body: { FormGUID: formId, Questions: Object.values(body) }
      };
      if (token != "") {
        form.headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        };
      }
      const { message } = await dispatch(form);
      NotificationManager.success(message);
      browserHistory.push({
        pathname: "/thanks",
        state: { backTo: "/questionnaires" }
      });
    } catch (error) {
      console.error(error.message);
      NotificationManager.error(error.message);
    }
  };