import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import queryString from "query-string";
import { fetchComplianceForm, submitForm } from "actions/compliance-forms";
import { Page } from "components/ui";
import useTranslate from "hooks/use-translate-language";
import { Formik, Form } from "formik";
import { Button } from "@mui/material";
import LogicalQuestion from "./logical-question";
import FreeTextQuestion from "./free-text-question";
import MultiSelectQuestion from "./multi-select-question";
import MultipleChoiceQuestion from "./mutiple-choice-question";

const FillOutComplianceForms = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [formData, setFormData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [values, setValues] = useState({});

  const location = useLocation();
  const authToken = useSelector(selectors.getAuthenticationToken);
  const tokenString = queryString.parse(location.search).token ?? authToken;
  const formId = queryString.parse(location.search).form;

  useEffect(() => {
    if (formId) {
      setIsFetching(true);
      dispatch(fetchComplianceForm(formId, tokenString))
        .then(data => {
          setFormData(data);

          let values = {};
          data.Questions.map(q => {
            const QuestionId = q.FormQuestionsID;
            values[QuestionId.toString()] = { QuestionId };
          });
          setValues(values);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [formId]);

  const getQuestionInput = (q, setFieldValue) => {
    const strQuestionId = q.FormQuestionsID.toString();
    switch (q.FormQuestionTypeID) {
      case 1:
        return (
          <FreeTextQuestion
            questionText={q.QuestionText}
            type={"text"}
            questionId={strQuestionId}
            setFieldValue={setFieldValue}
            hasNAOption={q.HasNAOption}
            isRequired={q.IsRequired}
          />
        );
      case 2:
        return (
          <MultipleChoiceQuestion
            questionText={q.QuestionText}
            options={q.QuestionOptions}
            questionId={strQuestionId}
            setFieldValue={setFieldValue}
            hasNAOption={q.HasNAOption}
          />
        );
      case 3:
        return (
          <LogicalQuestion
            questionId={strQuestionId}
            questionText={q.QuestionText}
            hasNAOption={q.HasNAOption}
            setFieldValue={setFieldValue}
          />
        );
      case 4:
        return (
          <FreeTextQuestion
            questionText={q.QuestionText}
            type={"number"}
            questionId={strQuestionId}
            setFieldValue={setFieldValue}
            hasNAOption={q.HasNAOption}
          />
        );
      case 5:
        return (
          <MultiSelectQuestion
            questionText={q.QuestionText}
            options={q.QuestionOptions}
            questionId={strQuestionId}
            setFieldValue={setFieldValue}
            hasNAOption={q.HasNAOption}
          />
        );
      default:
        break;
    }
  };

  const validate = (values, setFieldError) => {
    let err = false;
    formData.Questions.map(q => {
      if (q.IsRequired) {
        const answer = values[q.FormQuestionsID.toString()];

        switch (q.FormQuestionTypeID) {
          case 1:
            if (!answer.TextAnswer && !answer.MarkedAsNA) {
              setFieldError(q.FormQuestionsID.toString(), "Required");
              err = true;
            }
            break;
          case 2:
            if (!answer.QuestionOptionId  && !answer.MarkedAsNA) {
              setFieldError(q.FormQuestionsID.toString(), "Required");
              err = true;
            }
            break;
          case 3:
            if (
              (!answer.NumericAnswer ||
                (answer.NumericAnswer != "1" && answer.NumericAnswer != "0")) &&
              !answer.MarkedAsNA
            ) {
              setFieldError(q.FormQuestionsID.toString(), "Required");
              err = true;
            }
            break;
          case 4:
            if (
              (!answer.NumericAnswer || answer.NumericAnswer < 0) &&
              !answer.MarkedAsNA
            ) {
              setFieldError(q.FormQuestionsID.toString(), "Required");
              err = true;
            }
            break;
          case 5:
            if (
              (!answer.QuestionOptionIdList ||
                answer.QuestionOptionIdList.length == 0) &&
              !answer.MarkedAsNA
            ) {
              setFieldError(q.FormQuestionsID.toString(), "Required");
              err = true;
            }
            break;
        }
      }
    });
    return err;
  };

  return (
    <Page.Container>
      <Page.Content isFetching={isFetching}>
        {formData && (
          <div className="sign-questionnaire">
            <h2>{translate("completeForm")}</h2>
            <h3>{formData.Name}</h3>
            <Formik
              initialValues={values}
              onSubmit={async (values, { setFieldError }) => {
                const errors = validate(values, setFieldError);

                if (errors) return;

                await dispatch(submitForm(formId, values, tokenString));
              }}
              enableReinitialize={true}
            >
              {({ isValid, isSubmitting, setFieldValue, handleChange }) => (
                <Form className="questionnaire-form">
                  {formData &&
                    formData.Questions &&
                    formData.Questions.map(q => (
                      <div
                        className="question-container"
                        key={q.FormQuestionsID}
                      >
                        {getQuestionInput(q, setFieldValue, handleChange)}
                      </div>
                    ))}
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      alignItems: "center"
                    }}
                  >
                    {isFetching ? (
                      <Spinner />
                    ) : (
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        disabled={isFetching || !isValid || isSubmitting}
                      >
                        {translate("submit")}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Page.Content>
    </Page.Container>
  );
};

export default FillOutComplianceForms;
